import React from 'react';
import './DepartamentsInfo.css'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchDeparInfoSlice } from '../Redux/DepartamentSlice';


const DepartamentsInfo = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchDeparInfoSlice(id))
    }, [dispatch, id])

    const departament = useSelector(state => state?.depart?.data)

    return (
        <div className='container  '>
            <div className='row'>


                <h2 > {departament?.name} </h2>

                <div key={departament?.content?.id} className="content card-text" dangerouslySetInnerHTML={{ __html: departament?.content }}></div>
                {/* <div className='col-md-12' data-aos="fade-right" data-aos-duration="1000">

                </div> */}
            </div>
        </div >
    );
};

export default DepartamentsInfo;