import React from 'react';
import './Footer.css';
const Footter = () => {
  return (

    <footer className=' mt-4'>
      <div className='conatiner mt-4'>
        <div className=" pt-4">
        </div>

        <div className="text-center text-dark p-3" >
          <p>© 2013-2022: Istanbul  Hospital<br></br>
            Butun huquqlar qorunur</p>
        </div>
      </div>
    </footer>

  );
};

export default Footter;