import React, { useEffect } from 'react';
import './Content.css';
import doctor from '../img/1/3.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserDoctor, faVialCircleCheck, faHospital, faHeartPulse } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux';
import { fetchPrioritesSlice } from "../Redux/PrioritiesSlice"

const Content = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchPrioritesSlice())
    }, [dispatch])

    const Priorites = useSelector(state => state?.prioriteSlie?.data)
    // console.log("Priorities", Priorites?.data)

    return (
        <div className='container mt-5 mb-4 '>
            <div className='row justify-content-between'>


                <div className='col-md-12' >
                    <ul className='content-list'>
                        <li data-aos="fade-up"
                            data-aos-duration="1000">
                            <div className=" medicalInfo"  >
                                <div className='medicalIcons'>

                                    <FontAwesomeIcon icon={faHeartPulse} size="lg" className='medical-icon' />
                                    <span>{Priorites?.[0]?.title}</span>
                                </div>

                                <p> {Priorites?.[0]?.content} </p>
                            </div>

                        </li>

                        <li data-aos="fade-up"
                            data-aos-duration="1000">
                            <div className="medicalInfo" >
                                <div className="medicalIcons">

                                    <FontAwesomeIcon icon={faHospital} size="lg" className='medical-icon' />
                                    <span>{Priorites?.[1]?.title} </span>
                                </div>
                                <p>{Priorites?.[1]?.content}</p>
                            </div>
                        </li>
                        <li data-aos="fade-up"
                            data-aos-duration="1000">
                            <div className="medicalInfo" >
                                <div className="medicalIcons">
                                    <FontAwesomeIcon icon={faVialCircleCheck} size="lg" className='medical-icon' />
                                    <span>{Priorites?.[2]?.title} </span>
                                </div>
                                <p>{Priorites?.[2]?.content} </p>
                            </div>
                        </li>
                        <li data-aos="fade-up"
                            data-aos-duration="1000">
                            <div className="medicalInfo" >
                                <div className="medicalIcons">
                                    <FontAwesomeIcon icon={faUserDoctor} size="lg" className='medical-icon' />
                                    <span>{Priorites?.[3]?.title} </span>
                                </div>

                                <p> {Priorites?.[3]?.content}</p>
                            </div>
                        </li>
                    </ul>
                </div>
                {/* <div className='col-md-7 ' data-aos="fade-up" data-aos-duration="1000">
                    <div className='content-divider '>

                        <div className='content-img '>
                            <img src={doctor} className=" " alt="" />

                        </div>
                        <div className='divider'></div>
                    </div>
                </div> */}



            </div>
        </div>
    );
};

export default Content;