import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../Api/Api";

export const fetchPrioritesSlice = createAsyncThunk('prioritesSlices',
    async () => {
        const PrioritesApi = "/api/home/priorities";
        const response = await Api.get(`${PrioritesApi}`)
        console.log("PrioriteSice", response?.data)
        return response.data?.data
    }
)

export const initialState = {

    fetchPrioritesSlice
}
const prioritiesSlices = createSlice({
    name: "PrioritesSlices",
    initialState,
    reducers: {
        allProriteSlice: (state, { payload }) => {
            state.data = payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPrioritesSlice.fulfilled, (state, action) => {
            state.data = action.payload
        })
    }
})
export const { allProriteSlice } = prioritiesSlices.actions;
export const getAllAboutSlice = (state) => state.fetchPrioritesSlice;
export default prioritiesSlices.reducer;