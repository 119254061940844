import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fecthContactSlice } from '../Redux/ContactSlice';
import './Contact.css';

const ContactsHeader = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fecthContactSlice())
    }, [dispatch])

    const contact = useSelector(state => state?.contactSlice?.data?.data)

    return (
        <div className='Contact pt-1'>
            <div className='container headerInfo '>
                <div className='row '>
                    <div className=' col-md-12  header-tel '>
                        <div className='row'>
                            <div className='col-md-6'>
                                <ul className='con' >
                                </ul>
                            </div>
                            <div className='col-md-6'>
                                <ul className='sos '>
                                    {
                                        contact?.[2]?.details?.map((s) => (
                                            <li key={s.id} className='' data-aos="fade-down" data-aos-duration="1000"> <a href={s.link}  > <span dangerouslySetInnerHTML={{ __html: s?.icon }} ></span> </a></li>
                                        ))
                                    }
                                    <li data-aos="fade-down" data-aos-duration="1000"></li>
                                </ul>

                            </div>
                        </div>



                    </div>

                    {/* <div className='col-lg-auto Language' data-aos="fade-down" data-aos-duration="1000">
                        <p>AZ</p>
                        <p>EN</p>
                        <p>RU</p>
                    </div> */}


                </div>
            </div>
        </div>
    );
};

export default ContactsHeader;